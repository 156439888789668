<template>
  <div>
    <div class="no-print" style="margin-bottom: 60px"></div>

    <div v-html="rephtml" class="mx-auto"></div>

    <div class="no-print" style="display: block; width: 100%; position: fixed; z-index: 99999; top: 0; left: 0">
      <v-card elevation="1" flat>
        <v-card-text class="py-1 d-flex">
          <div style="width: 300px" class="d-flex justify-start">
            <v-btn @click="goBack()" color="primary" text>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </div>
          <v-spacer></v-spacer>
          <h3 class="pt-1">{{ title }}</h3>
          <v-spacer></v-spacer>
          <div style="width: 300px" class="d-flex justify-end">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="getDetail()" color="primary" class="ml-2" :loading="loading" icon><v-icon>mdi-refresh</v-icon></v-btn>
              </template>
              <span>Reload</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="printAction()" color="primary" class="ml-2" icon> <v-icon>mdi-printer</v-icon> </v-btn>
              </template>
              <span>Print</span>
            </v-tooltip>
            
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<style scoped>
@media print {
  body {
    margin: 0 !important;
  }

  .no-print {
    display: none !important;
  }
}
</style>

<script>
export default {
  components: {},
  data: () => ({
    title: "Print Comment",
    rephtml: "",
    loading: false,
  }),
  methods: {
    async getDetail() {
      this.loading = true;

      // this.showLoadingOverlay(true);

      var config = {
        params: {
          document_id: this.$route.query.document_id,
          source_document: this.$route.query.source_document,
          document_no_: this.$route.query.document_no_,
        },
        cacheConfig: false,
      };
      await this.$axios
        .get("comments/print-preview", config)
        .then((res) => {
          this.loading = false;
          this.rephtml = res.data;
          // this.showLoadingOverlay(false);

          // setTimeout(() => {
          //   this.getDetail();
          // }, 1000);
        })
        .catch((error) => {
          this.loading = false;
          // this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
    printAction() {
      window.print();
    },
  },
  mounted() {
    this.getDetail();
  },
};
</script>
